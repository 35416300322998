
    const css = `.qrCodes {
  width: 100%;
  max-width: 900px;
  background-color: #a2ded0;
  display: flex;
  flex-direction: row;
  margin: 1rem 0 1rem 2rem;
  z-index: 2;
  border-radius: 5px;
}
.qrCodeContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  padding: 2px;
}
#qrCodeForm {
  width: 100%;
}
.contentContainer {
  margin: 1rem;
  width: 80%;
  display: flex;
  align-items: center;
}
input {
  width: 60%;
  background-color: #ffffff;
  border-radius: 5px;
  height: 26px;
  padding: 5px 0 0 5px;
  border: none;
  margin: 1rem 0;
}
img {
  width: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 7px;
  height: auto;
  justify-self: end;
  z-index: 1;
  cursor: pointer;
}

.qrCodeContainer .generateText {
  position: relative;
  left: 72%;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}
p {
  width: 60%;
  background-color: #ffffff;
  border-radius: 5px;
  height: 26px;
  padding: 5px 0 0 5px;
}
h2 {
  margin: 0;
}

.qrCodeSection {
  display: flex;
  align-items: center;
  z-index: 3;
}

.qrCodes:hover .qrCodeContainer .generateText {
  opacity: 1;
}

.qrCodes:hover .qrCodeContainer img {
  opacity: 0.3;
}
@media only screen and (max-width: 900px) {
  .qrCodes {
    width: 90%;
    margin: 20px auto;
  }
}`;
    export default css;
  