import { addDoc, collection, db } from "../../firebase/firebaseConfig.js";
import { throwSnackbar } from "../services/snackBar.js";
import css from "./qrCodeGeneratorCSS.js";

class QRCodeGenerator extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.projectID = JSON.parse(localStorage.getItem("selectedProject"));
    this.userRole = JSON.parse(localStorage.getItem("selectedProjectUserRole"));
    this.shadowRoot.innerHTML = `
    ${
      this.userRole != "view"
        ? `
  <div class="qrCodeSection">
    <div class="qrCodes">
      <div class="contentContainer">
      <form id="qrCodeForm">
      <input type="text" id="title" name="title" placeholder="Titel">
      <input type="url" id="redirectUrl" name="redirectUrl" placeholder="https:ohooo.ch">
    </form>
      </div>
      <div class="qrCodeContainer">
      <h3 class="generateText">Generate Qr-Code</h3>
        <img
          src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&amp;data=www.ohooo.ch">
      </div>
    </div>
  </div>`
        : ""
    }
    `;

    const styles = document.createElement("style");
    this.shadowRoot.appendChild(styles);

    async function loadCSS() {
      styles.textContent = css;
    }
    loadCSS();
    this.titleInput = this.shadowRoot.getElementById("title");
    this.redirectUrlInput = this.shadowRoot.getElementById("redirectUrl");
    this.generateButton = this.shadowRoot.querySelector(".qrCodeContainer");
    window.addEventListener("projectSelected", (event) => {
      this.projectID = event.detail;
    });
  }

  async generateQRCode() {
    const title = this.titleInput.value;
    const redirectUrl = this.redirectUrlInput.value;
    try {
      let analyticId = await this.setNewAnalyticsDoc();
      const docRef = await addDoc(collection(db, "qr-values"), {
        title: title,
        redirectUrl: redirectUrl,
        project: this.projectID,
        analytic: analyticId,
        color: "black",
      });
      throwSnackbar("Dokument erfolgreich gespeichert", "black", "green");
    } catch (error) {
      throwSnackbar("Dokument konnte nicht gespeichert werden", "black", "red");
      console.log(error);
    }
  }

  async setNewAnalyticsDoc() {
    const newDocRef = await addDoc(collection(db, "analytics"), {});
    const newDocID = newDocRef.id;
    return newDocID;
  }

  connectedCallback() {
    this.generateButton.addEventListener("click", () => this.generateQRCode());
  }
}
export function receiveProjectId(project) {
  this.projectID = project;
}

customElements.define("qr-code-generator", QRCodeGenerator);
